import { useEffect, useRef, useState } from "react"
import { useNavigate } from 'react-router-dom'
import { UserGetMe, UserShow, UserUpdateMe } from "../../lib/backend/user"
import { Skeleton } from "../../components/ui/skeleton"
import { WeddingCreate, WeddingIndex, WeddingShow, WeddingUpdate } from "../../lib/backend/wedding"
import { Wedding } from "../../../../backend/models/wedding"
import { Button } from "../../components/ui/button"
import { DataTable } from "../../components/TemplateTable"
import { Rows, X } from "lucide-react"

import { ArrowUpDown, MoreHorizontal } from "lucide-react"

import {
	Dialog,
	DialogClose,
	DialogFooter,
	DialogContent,
	DialogDescription,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "../../components/ui/dialog"

import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "../../components/ui/alert-dialog"

import { dateToNiceString } from "../../lib/utils"
import { WeddingForm } from "../../components/WeddingForm"
import { Label } from "../../components/ui/label"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../components/ui/select"
import { User } from "../../../../backend/models/user"
import { AgGridReact } from "ag-grid-react"
import { Invoice } from "../../../../backend/models/invoice"
import { InactiveMessage } from "../../components/InactiveMessage"
import { GetSession } from "../../lib/backend/auth"
import { getCookie, setCookie } from "../../lib/cookie"

type WeddingTableRow = Pick<Wedding, "id" | "user_id" | "general_wedding_name" | "wedding_information_event_details_marriage_date" | "general_archive_wedding"> & { invoices: Invoice[] }

export default function WeddingsPage() {

	const [initialLoading, setInitialLoading] = useState(true)
	const gridRef = useRef<AgGridReact>(null);
	const [rows, setRows] = useState<WeddingTableRow[]>([])
	const [originalRows, setOriginalRows] = useState<WeddingTableRow[]>([])
	const [currentRow, setCurrentRow] = useState<Wedding & { invoices: Invoice[] }>(defaultRow)
	const [currentRowOriginal, setCurrentRowOriginal] = useState<Wedding & { invoices: Invoice[] }>(defaultRow)
	const [showModal, setShowModal] = useState(false)
	const [create, setCreate] = useState(false)
	const [loading, setLoading] = useState(true)
	const [weddingFilter, setWeddingFilter] = useState<User["wedding_select"]>("Show all weddings")
	const [weddingNameSort, setWeddingNameSort] = useState<"Ascending" | "Descending" | "Normal" | null>(null)
	const [weddingDateSort, setWeddingDateSort] = useState<"Ascending" | "Descending" | "Normal" | null>(null)
	const [userType, setUserType] = useState<"User" | "Admin">("User")
	const [invoiceChanges, setInvoiceChanges] = useState<boolean[]>([])
	const [user, setUser] = useState<{ id: number, manager: User["manager"], status: User["status"] }>({ id: 0, manager: "", status: "A" })

	const navigate = useNavigate();

	useEffect(() => {
		getFilesData()
	}, [])

	async function getFilesData() {
		//
		const myWeddingsResponse = await WeddingIndex()
		if (myWeddingsResponse.ok) {
			//
			const myWeddingsBody = await myWeddingsResponse.json()
			//
			// console.log(myWeddingsBody[0])
			//
			// const myWeddingsBodyClean = myWeddingsBody.map((wedding: Wedding) => {
			// 	if (wedding.wedding_information_event_details_marriage_date) {
			// 		///@ts-ignore
			// 		wedding.wedding_date_string = dateToNiceString(new Date(wedding.wedding_information_event_details_marriage_date))
			// 	} else {
			// 		///@ts-ignore
			// 		wedding.wedding_date_string = ""
			// 	}
			// 	return wedding
			// })
			//
			const myUserResponse = await UserGetMe()
			if (myUserResponse.ok) {
				//
				const myUserBody = await myUserResponse.json()
				//
				setUserType(myUserBody.manager)
				setWeddingFilter(myUserBody.wedding_select)
				setWeddingNameSort(myUserBody.wedding_column_order)
				setWeddingDateSort(myUserBody.wedding_column_date_order)
				//
				// const filteredWeddings: WeddingTableRow[] = filterWeddings(myUserBody.wedding_select, myWeddingsBodyClean)
				const filteredWeddings: WeddingTableRow[] = filterWeddings(myUserBody.wedding_select, myWeddingsBody)
				//
				setRows(filteredWeddings)
				setOriginalRows(myWeddingsBody)
				//
				let nameSort: "asc" | "desc" | null | undefined = null
				if (myUserBody.wedding_column_order === "Ascending") {
					nameSort = "asc"
				} else if (myUserBody.wedding_column_order === "Descending") {
					nameSort = "desc"
				}
				//
				let dateSort: "asc" | "desc" | null | undefined = null
				if (myUserBody.wedding_column_date_order === "Ascending") {
					nameSort = null
					dateSort = "asc"
				} else if (myUserBody.wedding_column_date_order === "Descending") {
					nameSort = null
					dateSort = "desc"
				}
				//
				gridRef.current?.api.applyColumnState({
					state: [
						{ colId: "general_wedding_name", sort: nameSort },
						{ colId: "wedding_information_event_details_marriage_date" },
						{ colId: "wedding_date_string", sort: dateSort },
					],
					defaultState: { hide: false },
				})
				//
				const response = await GetSession()
				if (!response.ok) {
					navigate("/login")
				} else {
					const body = await response.json()
					const meResponse = await UserShow(body.session.id)
					if (meResponse.ok) {
						const meBody = await meResponse.json()
						setUser(meBody)
						setInitialLoading(false)
					}
				}
			}
			//
		} else {
			//
			navigate("/login")
			//
		}
		//
	}

	async function onNewClick() {
		//
		setCurrentRow(defaultRow)
		setCurrentRowOriginal(defaultRow)
		setCreate(true)
		setLoading(false)
		setShowModal(true)
		//
	}

	async function onRowClick(id: number) {
		//
		setLoading(true)
		setCurrentRow(defaultRow)
		setCurrentRowOriginal(defaultRow)
		setCreate(false)
		setShowModal(true)
		//
		const selectedRow = rows.find((row) => row.id === id)
		if (selectedRow) {
			const weddingResponse = await WeddingShow(id)
			if (weddingResponse.ok) {
				const weddingBody = await weddingResponse.json()
				setCurrentRow(weddingBody)
				setCurrentRowOriginal(weddingBody)
				setInvoiceChanges(Array.from({ length: weddingBody.invoices.length }, () => false))
				setLoading(false)
			} else {
				navigate("/login")
			}
		}
		//
	}

	function filterWeddings(value: User["wedding_select"], originalRowsParam: WeddingTableRow[]): WeddingTableRow[] {
		//
		let filteredRows = originalRowsParam
		//
		if (value === "Show all weddings") {
			//
		} else if (value === "Hide archived weddings") {
			filteredRows = originalRowsParam.filter(row => row.general_archive_wedding === "No")
		} else if (value === "Show ONLY archived weddings") {
			filteredRows = originalRowsParam.filter(row => row.general_archive_wedding === "Yes")
		}
		//
		return filteredRows
		//
	}

	async function weddingFilterChanged(value: User["wedding_select"]) {
		//
		setWeddingFilter(value)
		//
		const filteredRows = filterWeddings(value, originalRows)
		//
		setRows(filteredRows)
		//
		const updateResult = await UserUpdateMe({ user: { wedding_select: value } })
		// console.log({ updateResult })
		//
	}

	async function sortChanged(event: any) {
		const id = event.columns[0].colId
		const sort = event.columns[0].sort
		// console.log({ id })
		// console.log({ sort })
		//
		let newValues: any = {
			wedding_column_order: null,
			wedding_column_date_order: null,
		}
		//
		if (id === "general_wedding_name") {
			if (sort === "asc") {
				newValues.wedding_column_order = "Ascending"
			} else if (sort === "desc") {
				newValues.wedding_column_order = "Descending"
			} else {
				newValues.wedding_column_order = ""
			}
			newValues.wedding_column_date_order = ""
		} else {
			if (sort === "asc") {
				newValues.wedding_column_date_order = "Ascending"
			} else if (sort === "desc") {
				newValues.wedding_column_date_order = "Descending"
			} else {
				newValues.wedding_column_date_order = ""
			}
			newValues.wedding_column_order = ""
		}
		//
		const updateResult = await UserUpdateMe({ user: newValues })
		// console.log({ updateResult })
		//
	}

	return (
		<div className="px-2 mt-4">
			<div>
				<div className="text-2xl font-semibold mb-4 flex justify-between">
					<h1 className="text-3xl font-bold" data-testid="Weddings">
						Weddings
					</h1>
				</div>
				<div className="w-20 h-20 mt-8" hidden={!initialLoading}>
					<svg aria-hidden="true" className="w-full text-left h-full m-0 mt-6 mb-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
						<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
					</svg>
				</div>
				<div hidden={initialLoading} className="mt-8 w-full">
					<InactiveMessage />
					<DataTable
						gridRef={gridRef}
						sortChanged={sortChanged}
						columns={[
							{ field: "id", headerName: "ID", flex: 1, hide: userType !== "Admin" },
							{ field: "user_id", headerName: "User", flex: 1, hide: userType !== "Admin" },
							{ field: "general_wedding_name", headerName: "Wedding Name", flex: 4 },
							{ field: "wedding_date_string", headerName: "Wedding Date", type: "date", flex: 2 },
						]}
						data={rows}
						onNewClick={onNewClick}
						onRowClick={onRowClick}
						createButtonText="Create Wedding"
						underComponent={<div className="max-w-[400px] items-center mb-6">
							<Label
								htmlFor="wedding_select"
								className="text-base text-gray-600 font-light mt-5"
							>
								Hide Archived?
							</Label>
							<Select
								name="wedding_select"
								value={weddingFilter}
								onValueChange={(value: User["wedding_select"]) => weddingFilterChanged(value)}
							>
								<SelectTrigger
									id="wedding_select"
									className="w-full bg-white text-lg mt-2 mb-3"
									name="wedding_select"
								>
									<SelectValue placeholder="Select a filter" />
								</SelectTrigger>
								<SelectContent className="text-lg">
									<SelectItem value="Show all weddings" className="text-lg">Show all weddings</SelectItem>
									<SelectItem value="Hide archived weddings" className="text-lg">Hide archived weddings</SelectItem>
									<SelectItem value="Show ONLY archived weddings" className="text-lg">Show ONLY archived weddings</SelectItem>
								</SelectContent>
							</Select>
						</div>}
						disableCreateButton={user.status === "I"}
					/>
				</div>
				<WeddingDialog
					openParam={showModal}
					setOpen={setShowModal}
					create={create}
					setCreate={setCreate}
					weddingData={currentRow}
					originalRow={currentRowOriginal}
					setWeddingData={setCurrentRow}
					setOriginalRow={setCurrentRowOriginal}
					loading={loading}
					setRows={setRows}
					rows={rows}
					invoiceChanges={invoiceChanges}
					setInvoiceChanges={setInvoiceChanges}
					user={user}
				/>
			</div>
		</div>
	)
}

export function WeddingDialog(props: {
	openParam: boolean,
	setOpen: any,
	create: boolean,
	setCreate: Function,
	weddingData: Wedding & { invoices: Invoice[] },
	originalRow: Wedding & { invoices: Invoice[] },
	setWeddingData: Function,
	setOriginalRow: Function,
	loading: boolean,
	rows: WeddingTableRow[],
	setRows: Function,
	invoiceChanges: boolean[],
	setInvoiceChanges: Function,
	user: { id: number, manager: User["manager"], status: User["status"] },
}) {

	const [openConfirm, setOpenConfirm] = useState(false)

	const navigate = useNavigate();

	async function tryToClose() {
		if (JSON.stringify(props.weddingData) !== JSON.stringify(props.originalRow)) {
			setOpenConfirm(true)
		} else {
			props.setOpen(false)
		}
	}

	async function getResult(result: boolean) {
		setOpenConfirm(false)
		if (result) {
			props.setOpen(false)
		}
	}

	async function submitData() {
		//
		const weddingData = props.weddingData
		if (props.create) {
			//
			const createResponse = await WeddingCreate(weddingData)
			if (createResponse.ok) {
				//
				const createBody = await createResponse.json()
				const newRows = JSON.parse(JSON.stringify(props.rows))
				if (createBody.wedding_information_event_details_marriage_date) {
					///@ts-ignore
					createBody.wedding_date_string = dateToNiceString(new Date(createBody.wedding_information_event_details_marriage_date))
				} else {
					///@ts-ignore
					createBody.wedding_date_string = ""
				}
				newRows.push(createBody)
				props.setRows(newRows)
				props.setWeddingData(createBody)
				props.setOriginalRow(createBody)
				props.setInvoiceChanges(Array.from({ length: createBody.invoices.length }, () => false))
				props.setCreate(false)
				// props.setOpen(false)
				//
			} else {
				//
				navigate("/login")
				//
			}
			//
		} else {
			//
			const updateResponse = await WeddingUpdate(weddingData.id, weddingData)
			if (updateResponse.ok) {
				//
				const updateBody = await updateResponse.json()
				if (updateBody.wedding_information_event_details_marriage_date) {
					///@ts-ignore
					updateBody.wedding_date_string = dateToNiceString(new Date(updateBody.wedding_information_event_details_marriage_date))
				} else {
					///@ts-ignore
					updateBody.wedding_date_string = ""
				}
				const updatedRows = props.rows.map(row => {
					if (row.id === updateBody.id) {
						return updateBody
					}
					return row
				})
				props.setRows(updatedRows)
				props.setWeddingData(updateBody)
				props.setOriginalRow(updateBody)
				props.setInvoiceChanges(Array.from({ length: updateBody.invoices.length }, () => false))
				// props.setOpen(false)
				//
			} else {
				//
				navigate("/login")
				//
			}
			//
		}
		//

	}

	//
	return (
		<>
			<Dialog
				defaultOpen={true}
				open={props.openParam}
				// onOpenChange={() => {
				// 	console.log("Trying to open/close")
				// }}
				modal={true}
			>
				<DialogContent className="">
					<DialogHeader>
						<DialogTitle>
							<div className="flex flex-row justify-between align-middle w-full h-10">
								<h2 className="text-3xl -mt-1">
									{props.create ? "Create Wedding" : "Maintain Wedding"}
								</h2>
								<Button
									className="-m-2 px-2 outline-none ring-0 ring-transparent ring-white border-0 text-gray-600"
									variant={"outline"}
									onClick={() => {
										tryToClose()
									}}
								>
									<X />
								</Button>
							</div>
						</DialogTitle>
					</DialogHeader>
					<div
						className="-mt-1 mb-14 sm:mb-1 overflow-y-auto"
						style={{ height: "70vh" }}
						hidden={props.loading}
					>
						<WeddingForm
							weddingData={props.weddingData}
							setWeddingData={props.setWeddingData}
							invoiceChanges={props.invoiceChanges}
							setInvoiceChanges={props.setInvoiceChanges}
							status={props.user.status}
						/>
					</div>
					<div className="w-full h-full" hidden={!props.loading}>
						<svg aria-hidden="true" className="w-full text-center h-20 m-0 mt-6 mb-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
							<path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
						</svg>
					</div>
					<div hidden={props.loading} className="h-8">
						<DialogFooter className="h-full">
							{/* <DialogClose asChild={true}> */}
							<Button
								data-testid="closeWedding"
								variant={"outline"}
								type="button"
								className="text-lg"
								onClick={() => {
									tryToClose()
								}}
							>
								Close
							</Button>
							{/* </DialogClose> */}
							{/* <DialogClose asChild={false}> */}
							<Button
								data-testid="createUpdateWedding"
								variant={"default"}
								type="submit"
								className="text-lg"
								onClick={submitData}
								disabled={props.loading || JSON.stringify(props.weddingData) === JSON.stringify(props.originalRow) || props.user.status === "I"}
							>
								{props.create ? "Create Wedding" : "Update Wedding"}
							</Button>
							{/* </DialogClose> */}
						</DialogFooter>
					</div>
				</DialogContent>
			</Dialog>
			<ConfirmDialog openConfirm={openConfirm} create={props.create} getResult={getResult} />
		</>
	)
}

export function ConfirmDialog(props: { openConfirm: boolean, create: boolean, getResult: any }) {
	return (
		<AlertDialog
			defaultOpen={false}
			open={props.openConfirm}
		>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>Unsaved Changes</AlertDialogTitle>
					<AlertDialogDescription className="text-lg my-8">
						You have made changes to this wedding.
						<br />
						Are you sure you want to close without saving and lose all your changes?
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogCancel
						className="text-lg bg-destructive hover:bg-red-400 text-white hover:text-white"
						onClick={() => {
							props.getResult(true)
						}}
					>
						Yes. Remove my changes.
					</AlertDialogCancel>
					<AlertDialogAction
						className="text-lg"
						onClick={() => {
							props.getResult(false)
						}}
					>
						No. Take me back to the wedding.
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

export function MessageDialog(props: { openConfirm: boolean, title: string, body: any, getResult: any }) {
	return (
		<AlertDialog
			defaultOpen={false}
			open={props.openConfirm}
		>
			<AlertDialogContent>
				<AlertDialogHeader>
					<AlertDialogTitle>{props.title}</AlertDialogTitle>
					<AlertDialogDescription className="text-lg my-8">
						{props.body}
					</AlertDialogDescription>
				</AlertDialogHeader>
				<AlertDialogFooter>
					<AlertDialogAction
						className="text-lg"
						onClick={() => {
							setCookie('celebrant-briefcase-intro', 'Saved')
							props.getResult()
						}}
					>
						Close
					</AlertDialogAction>
				</AlertDialogFooter>
			</AlertDialogContent>
		</AlertDialog>
	)
}

const defaultRow: Wedding & { invoices: Invoice[] } = {

	id: 0,

	user_id: 0,

	// General
	general_wedding_name: "",
	general_archive_wedding: "No",
	general_public_link_locked: "Yes",
	general_wedding_notes: "",
	general_form15_cert_number: "",

	// Wedding Information
	// Event Details
	//@ts-ignore
	wedding_information_event_details_marriage_time: null,
	//@ts-ignore
	wedding_information_event_details_marriage_date: null,
	wedding_information_event_details_marriage_location: "",
	wedding_information_event_details_marriage_city: "",
	wedding_information_event_details_marriage_postcode: "",
	wedding_information_event_details_marriage_state_australia: "",
	wedding_information_event_details_marriage_state_other: "",
	wedding_information_event_details_leave_place_solemnized_empty: "No",

	// Previously Married
	//@ts-ignore
	wedding_information_person_1_date_last_marriage_ended: null,
	//@ts-ignore
	wedding_information_person_2_date_last_marriage_ended: null,

	// Dec/Cert
	wedding_information_marriage_witness_1: "",
	wedding_information_marriage_witness_2: "",
	wedding_information_person_1_declaration_place: "",
	wedding_information_person_2_declaration_place: "",
	//@ts-ignore
	wedding_information_person_1_declaration_date: null,
	//@ts-ignore
	wedding_information_person_2_declaration_date: null,
	wedding_information_ceremony_used: "Civil ceremony according to the Marriage Act 1961",

	// Extra NOIM Info
	//@ts-ignore
	wedding_information_extra_noim_date_noim_received_by_celebrant: null,
	wedding_information_extra_noim_rites_used: "",
	wedding_information_extra_noim_marriage_witness_1: "",
	wedding_information_extra_noim_marriage_witness_2: "",
	wedding_information_extra_noim_authorized_witness_1: "",
	wedding_information_extra_noim_authorized_witness_2: "",

	// Under 18
	wedding_information_person_1_under_18: "No",
	wedding_information_person_2_under_18: "No",
	wedding_information_person_1_under_18_court_name: "",
	wedding_information_person_2_under_18_court_name: "",
	wedding_information_person_1_under_18_court_location: "",
	wedding_information_person_2_under_18_court_location: "",
	//@ts-ignore
	wedding_information_person_1_under_18_court_date: null,
	//@ts-ignore
	wedding_information_person_2_under_18_court_date: null,
	wedding_information_person_1_under_18_consent_received: "No",
	wedding_information_person_2_under_18_court_approval: "No",

	// Parties Related/Signatures
	wedding_information_parties_related: "No",
	wedding_information_relationship: "",
	//@ts-ignore
	wedding_information_person_1_noim_signature_date: null,
	//@ts-ignore
	wedding_information_person_2_noim_signature_date: null,

	// Party 1/2
	party12_person_1_description: "Partner",
	party12_person_2_description: "Partner",
	party12_person_1_surname: "",
	party12_person_2_surname: "",
	party12_person_1_sex: "Non-binary",
	party12_person_2_sex: "Non-binary",
	party12_person_1_first_name: "",
	party12_person_2_first_name: "",
	party12_person_1_occupation: "",
	party12_person_2_occupation: "",
	party12_person_1_address_1: "",
	party12_person_1_address_2: "",
	party12_person_2_address_1: "",
	party12_person_2_address_2: "",
	party12_person_1_city: "",
	party12_person_2_city: "",
	party12_person_1_postcode: "",
	party12_person_2_postcode: "",
	party12_person_1_state: "",
	party12_person_2_state: "",
	party12_person_1_state_other: "",
	party12_person_2_state_other: "",
	party12_person_1_town_of_birth: "",
	party12_person_2_town_of_birth: "",
	party12_person_1_birthplace_state: "",
	party12_person_2_birthplace_state: "",
	party12_person_1_birthplace_state_other: "",
	party12_person_2_birthplace_state_other: "",
	party12_person_1_conjugal: "Never validly married",
	party12_person_2_conjugal: "Never validly married",
	//@ts-ignore
	party12_person_1_dob: null,
	//@ts-ignore
	party12_person_2_dob: null,
	party12_person_1_parent_1_full_current_name: "",
	party12_person_2_parent_1_full_current_name: "",
	party12_person_1_parent_1_full_birth_name: "",
	party12_person_2_parent_1_full_birth_name: "",
	party12_person_1_parent_2_full_current_name: "",
	party12_person_2_parent_2_full_current_name: "",
	party12_person_1_parent_2_full_birth_name: "",
	party12_person_2_parent_2_full_birth_name: "",
	party12_person_1_parent_1_country_of_birth: "",
	party12_person_2_parent_1_country_of_birth: "",
	party12_person_1_parent_2_country_of_birth: "",
	party12_person_2_parent_2_country_of_birth: "",

	// Noim Particulars
	noim_particulars_person_1_evidence: "Birth certificate or official extract",
	noim_particulars_person_2_evidence: "Birth certificate or official extract",
	noim_particulars_person_1_evidence_document_number: "",
	noim_particulars_person_2_evidence_document_number: "",
	noim_particulars_person_1_identity_confirmed: "No",
	noim_particulars_person_2_identity_confirmed: "No",
	noim_particulars_person_1_type_of_id: "",
	noim_particulars_person_2_type_of_id: "",
	noim_particulars_person_1_id_document_number: "",
	noim_particulars_person_2_id_document_number: "",
	noim_particulars_person_1_state_territory_or_country: "",
	noim_particulars_person_2_state_territory_or_country: "",
	noim_particulars_person_1_how_last_marriage_ended: "",
	noim_particulars_person_2_how_last_marriage_ended: "",
	noim_particulars_person_1_death_certificate: "",
	noim_particulars_person_2_death_certificate: "",
	noim_particulars_person_1_court_location: "",
	noim_particulars_person_2_court_location: "",
	noim_particulars_person_1_document_referred: "No",
	noim_particulars_person_2_document_referred: "No",
	noim_particulars_late_marriage_not_applicable: "Off",

	payment_total: 0,
	payment_received: 0,

	invoices: []

}
